$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/


$(document).ready(function(){
    
    //The position of the fixed navigation
    var $fixedNav = $('.page-navi').offset().top - 100;

    //Initializing the slider
    function sliderInit() {
         $('.slider-nav').on('mouseenter','.slick-slide',function(e){
            var $currTarget = $(e.currentTarget), 
            index = $currTarget.data('slick-index'),
            slickObj = $('.left-slider').slick('getSlick');

            slickObj.slickGoTo(index);
            
            });

            $('.left-slider').slick({
                dots:false,
                arrows:false,
                fade:true,
                speed:200
            });

            $('.slider-nav').slick({
                asNavFor:'.left-slider',
                slidesToShow:3 ,

                dots:false,
                arrows:false
        });

        $('.slider-nav a').on('mouseenter',function(){
            $(this).addClass('slick-current');
            $(this).siblings('.slick-current').removeClass('slick-current');
        });
    }

    //Teaser hover effect.
    function targetTeaser(){
        $('.inner-teaser a').mouseenter(function(){
            $(this).siblings('div').children('img').css({
                'transform' : 'scale(1.1)'
            });
        });

        $('.inner-teaser a').mouseleave(function(){
            $(this).siblings('div').children('img').css({
                'transform' : 'scale(1)'
            });
        });
    }

    //Fixed navi effect
    function fixedNavigation(){
        if($('body').hasClass('ie-10') || $('body').hasClass('ie-11')){
            if($(window).height() > 0 && $(window).scrollTop() > 0){
                $('body').addClass('fixed');
            }else {
                $('body').removeClass('fixed');
            }

        }else {
            if($(window).height() > 0 && $(window).scrollTop() > $fixedNav){
                $('body').addClass('fixed');
            }else {
                $('body').removeClass('fixed');
            }
        }
    }

    //Taking title from <a></a> tag and applying it into a div.
    function readTitle() {
        $('.gallery a').each(function(){
            var title = $(this).attr('title');
            $(this).append('<div class="img-text"><span>' + $(this).attr('title') + '</span></div>' );
            
        });

    }

    //Checking for gallery items if items < 1 || items === 0 , the whole arrea will be hidden.
    function zeroGalleryItems() {
        $('.gallery').each(function(){
            if($(this).children('div').children().length === 0){
                $('.outer-gallery').remove();
                $('.sub-main').css({'padding-bottom':'60px'});
            }
        })
    }


    //Initializing all functions
    sliderInit();
    targetTeaser();
    fixedNavigation();
    $(window).scroll(fixedNavigation);
    readTitle();
    zeroGalleryItems();

});